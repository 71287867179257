<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col-md-12">
        <div class="d-flex flex-column-fluid flex-center mt-12">
          <a href="#" class="text-center mb-10">
            <img src="media/novaImagens/logo.png" class="max-h-200px" alt="" />
          </a>
        </div>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "dashboard",
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Painel Inicial" }]);
  },
};
</script>
